import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { Switch } from "@chakra-ui/react";
import * as styles from "./mobile-drawer-menu.module.css";
import closeIcon from "./Close_MD.svg";

type MobileDrawerMenuProps = {
  isOpen: boolean;
  onClose?: () => void;
  selectedCategory: string[];
  onCategoryChange: (category: string) => void;
  isFreeOnly: boolean;
  onFreeOnlyChange: () => void;
  isFamilyFriendlyOnly: boolean;
  onFamilyFriendlyOnlyChange: () => void;
  sortDateAsc: boolean;
  onSortSelection: (selected: string) => void;
  }


const MobileDrawerMenu: FunctionComponent<MobileDrawerMenuProps> = ({
  isOpen,
  onClose,
  selectedCategory,
  onCategoryChange,
  isFreeOnly,
  onFreeOnlyChange,
  isFamilyFriendlyOnly,
  onFamilyFriendlyOnlyChange,
  sortDateAsc,
  onSortSelection,
}) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll("[data-animate-on-scroll]");

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, [selectedCategory, sortDateAsc]);

  return (
    <div className={`${styles.mobileDrawerMenu} ${isOpen ? styles.open : ""}`} data-animate-on-scroll>
      <div className={styles.frameParent}>
        <div className={styles.clearAllParent}>
          <div className={styles.clearAll}>Clear all</div>
          <div className={styles.vectorWrapper}>
            <img className={styles.vectorIcon} alt="" src={closeIcon} onClick={onClose} />
          </div>
        </div>
        <div className={styles.frameGroup}>
          <section className={styles.typeOfEventParent}>
            <div className={styles.typeOfEvent}>Type of event</div>
            <div className={styles.frameContainer}>
              <div className={styles.rectangleParent}>
                <input
                  className={styles.frameChild}
                  type="checkbox"
                  checked={selectedCategory.includes("at")}
                  onChange={() => onCategoryChange("at")}
                />
                <div className={styles.theArts}>The arts</div>
              </div>
              <div className={styles.rectangleParent}>
                <input
                  className={styles.frameChild}
                  type="checkbox"
                  checked={selectedCategory.includes("oc")}
                  onChange={() => onCategoryChange("oc")}
                />
                <div className={styles.theArts}>Outdoor concerts</div>
              </div>
              <div className={styles.rectangleParent}>
                <input
                  className={styles.frameChild}
                  type="checkbox"
                  checked={selectedCategory.includes("ww")}
                  onChange={() => onCategoryChange("ww")}
                />
                <div className={styles.theArts}>Workshops + workouts</div>
              </div>
              <div className={styles.rectangleParent}>
                <input
                  className={styles.frameChild}
                  type="checkbox"
                  checked={selectedCategory.includes("nf")}
                  onChange={() => onCategoryChange("nf")}
                />
                <div className={styles.theArts}>Neighborhood fun</div>
              </div>
            </div>
            <div className={styles.frameContainer}>
              <div className={styles.familyFriendlyParent}>
                <div className={styles.familyFriendly}>Family Friendly</div>
                <div className={styles.frameWrapper}>
                  <Switch
                    className={styles.frameSwitch}
                    size="sm"
                    colorScheme="red2"
                    isChecked={isFamilyFriendlyOnly}
                    onChange={onFamilyFriendlyOnlyChange}
                  />
                </div>
              </div>
              <div className={styles.familyFriendlyParent}>
                <div className={styles.familyFriendly}>Free</div>
                <div className={styles.frameWrapper}>
                  <Switch
                    className={styles.frameSwitch}
                    size="sm"
                    colorScheme="red2"
                    isChecked={isFreeOnly}
                    onChange={onFreeOnlyChange}
                  />
                </div>
              </div>
            </div>
            <div className={styles.sortByDate}>Sort by date</div>
            <div className={styles.frameContainer}>
              <div className={styles.rectangleParent}>
                <input
                  className={styles.frameChild}
                  type="checkbox"
                  checked={sortDateAsc}
                  onChange={() => onSortSelection("true")}
                />
                <div className={styles.theArts}>Ascending (June to September)</div>
              </div>
              <div className={styles.rectangleParent}>
                <input
                  className={styles.frameChild}
                  type="checkbox"
                  checked={!sortDateAsc}
                  onChange={() => onSortSelection("false")}
                />
                <div className={styles.theArts}>Descending (September to June)</div>
              </div>
            </div>
          </section>
          <button className={styles.showEventsWrapper} onClick={onClose}>
            <div className={styles.showEvents}>{`SHOW events`}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileDrawerMenu;
