function serializeParams(data) {
    return Object.keys(data)
      .map(key => `${key}=${encodeURIComponent(data[key])}`)
      .join("&");
  }
  

export const facebookShare = (link: string) => {
    const utmParams = {
        utm_source: "facebook",
        utm_medium: "referral",
        utm_campaign: "Summer-Guide-2023",
    };

    link + "?" + serializeParams(utmParams);

    const params = { u: link };

    return "https://www.facebook.com/sharer/sharer.php?" + serializeParams(params)

}

export const twitterShare = (link: string) => {
    const utmParams = {
        utm_source: "twitter",
        utm_medium: "referral",
        utm_campaign: "Summer-Guide-2023",
    };
    link + "?" + serializeParams(utmParams);

    const params = {
        url: link,
        text: "Plan your best summer ever with our searchable guide to the 250+ things to do in and around the city.",
      };

    return "https://twitter.com/share?" + serializeParams(params);
}

export const emailShare = (link: string) => {
    const utmParams = {
        utm_source: "email",
        utm_medium: "referral",
        utm_campaign: "Summer-Guide-2023",
    };

    link + "?" + serializeParams(utmParams);
    // copy needed
  const params = {
    subject: "We'll never run out of things to do!" ,
    body: `Plan your best summer ever with our searchable guide to the 250+ things to do in and around the city. ${link + "?" + serializeParams(utmParams)}`
  }

  return "mailto:?" + serializeParams(params);
}

export const copyLink = (link: string) => {
  navigator.clipboard.writeText(`${link}?utm_medium=url_copy`);
}