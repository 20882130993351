import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export const SEO = ({ title, description, pathname, children }) => {
  const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: 'https://cdn.wbez.org/images/summer23.png',
    url: siteUrl,
    twitterUsername: twitterUsername
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      {/* facebook */}

      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content="Chicago fun starts here. Your complete guide to the best summer events." />
      <meta property="og:description" content="Plan your best summer ever with our searchable guide to the 250+ things to do in and around the city." />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content="The WBEEZ Summer 250" />
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>

      
      {/* twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Concerts. Plays. Kayaks. Stargazing. Your best summer ever planning starts now." />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta property="twitter:image:alt" content="The WBEEZ Summer 250" />
      
      <link rel="canonical" href={seo.url} />
      {children}
    </>
  )
}