import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import StoryCard from "../components/story-card";
import * as styles from "./eventCardsContainer.module.css";

const RelatedStoriesContainer = () => {

  const { googleSheet } = useStaticQuery(graphql`
    query SecondQuery {
      googleSheet {
        Related_Stories { 
          title
          link
          teaser
          leadImage
          storyID
        }
      }
    }
  `);
  
  const storyData = googleSheet.Related_Stories;

  return (
      <div className={styles.eventCardsContainer}>
      {storyData.map((node) => {
          return (
            <StoryCard
              key={node.storyID}
              storyTitle={node.title}
              storyLink={node.link}
              storyTeaser={node.teaser}
              storyArt={node.leadImage}
            />
          );
        }
      )}
      </div>
  );
};

export default RelatedStoriesContainer;
