import * as React from "react";
import { FunctionComponent } from "react";
import * as styles from "./story-card.module.css";

type StoryCardType = {
  storyTitle?: string;
  storyLink?: string;
  storyTeaser?: string;
  storyArt?: string;
};

const StoryCard: FunctionComponent<StoryCardType> = ({
  storyTitle = "",
  storyLink="",
  storyTeaser = "",
  storyArt = "",
}) => {
  return (
    <div className={styles.storyCard}>
      <img className={styles.storyCardChild} alt="" src={storyArt} />
      <div className={styles.summerOfChicagoMusicNineParent}>
        <a
          className={styles.summerOfChicago}
          href={storyLink}
          target="_blank"
        >
          {storyTitle}
        </a>
        <div className={styles.itsSummerFestival}>{storyTeaser}</div>
      </div>
    </div>
  );
};

export default StoryCard;
