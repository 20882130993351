import React, { FunctionComponent, useState, useCallback, useEffect } from "react";
import SiteHeader from "../components/header";
import { Switch } from "@chakra-ui/react";
import { navigate } from "gatsby";
import MobileDrawerMenu from "../components/mobile-drawer-menu";
import PortalDrawer from "../components/portal-drawer";
import EventCardsContainer from "../components/eventCardsContainer";
import * as styles from "./events-final.module.css";
import facebook from "/facebook.svg";
import twitter from "/twitter.svg";
import email from "/email.svg";
import linkIcon from "/link.svg";
import logo from "/wbez-summer250-logo.png";
import filterIcon from "/filterIcon.svg";
import arrowIcon from "./arrow.svg";
import { SEO } from "../components/seo";
import { facebookShare, twitterShare, emailShare, copyLink } from "../components/helper-functions";
import RelatedStoriesContainer from "../components/relatedStoriesContainer";


const EventsFinal: FunctionComponent = ({ location, pageContext }) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [isFreeOnly, setIsFreeOnly] = useState(false);
  const [isFamilyFriendlyOnly, setIsFamilyFriendlyOnly] = useState(false);
  const [sortDateAsc, setSortDateAsc] = useState(true);
  const [clickedCat, setClickedCat] = useState({
    "oc": false,
    "at": false,
    "ww": false,
    "nf": false
  });
  const [isMobileDrawerMenuOpen, setMobileDrawerMenuOpen] = useState(false);
  // Update the selected category
  const handleCategoryChange = (category) => {
    let found = selectedCategory.indexOf(category);
    found === -1 
    ? setSelectedCategory([...selectedCategory, category])
    : setSelectedCategory(selectedCategory.filter(cat => cat !== category));
    clickedCat[category] = !clickedCat[category];
    setClickedCat(clickedCat);
  };

  // Update the free only filter
  const handleFreeOnlyChange = useCallback(() => {
    setIsFreeOnly(prevIsFreeOnly => !prevIsFreeOnly);
  }, []);

  // Update the family-friendly only filter
  const handleFamilyFriendlyOnlyChange = useCallback(() => {
    setIsFamilyFriendlyOnly(prevIsFamilyFriendlyOnly => !prevIsFamilyFriendlyOnly);
  }, []);

  const sortSelection = useCallback(selected => {
    setSortDateAsc(selected === "true");
  }, []);

  const jumpToCategories = () => {
    const move = document.getElementById("card-grid");
    move &&
      move.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  }

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, []);

  const openMobileDrawerMenu = useCallback(() => {
    setMobileDrawerMenuOpen(true);
  }, []);

  const closeMobileDrawerMenu = useCallback(() => {
    setMobileDrawerMenuOpen(false);
  }, []);

  const onNavigationBase1Click = useCallback(() => {
    navigate("/events-final");
  }, []);

  useEffect(() => {
     pageContext.routeCategory !== undefined
      ? (pageContext.routeCategory === "familyFriendly"
      ? setIsFamilyFriendlyOnly(true) 
      : handleCategoryChange(pageContext.routeCategory))
      : null
  }, []);

  return (
    
    <>
      <main className={styles.eventsFinal} id="main">
      <SiteHeader />
        <nav className={styles.sidebarMenuParent}>
          <nav className={styles.sidebarMenu} id="sidebar">
            {/* <div className={styles.sidebarMenuElements}>
              <button className={styles.home} onClick={onHomeClick}>
                <div className={styles.home1}>Home</div>
              </button>
              <button className={styles.home}>
                <div className={styles.home1}>Events</div>
              </button>
            </div> */}
            {/* SOCIAL ICONS */}
            <div className={styles.socialIcons}>
              <a className={styles.vectorWrapper} href={twitterShare(location.href)} target="_blank">
                <img className={styles.vectorIcon} alt="" src={twitter} />
             </a>
              <a className={styles.vectorContainer} href={emailShare(location.href)} target="_self" rel="noopener">
                <img className={styles.vectorIcon1} alt="" src={email} />
              </a>
              <a className={styles.vectorContainer} href={facebookShare(location.href)} target="_blank">
                <img className={styles.vectorIcon2} alt="" src={facebook} />
              </a>
              <button className={styles.vectorWrapper} onClick={() => copyLink(location.href)}>
                <img className={styles.vectorIcon3} alt="" src={linkIcon} />
              </button>
              <button className={styles.vectorWrapper} onClick={() => jumpToCategories() }>
                <img className={styles.vectorIcon3} alt="" src={arrowIcon} /> 
              </button>
            </div>
          </nav>
          <section className={styles.contentFrame} id="card-grid">
            <section className={styles.introduction}>
              <section className={styles.type}>
                <img
                className={styles.asset74x1}
                alt=""
                src={logo}
                />
                
              </section>
              <section className={styles.dek}> 
              {/* replace text here */}
              Build your summer bucket list from our epic guide of 250-plus things to do.
              </section>
              <section className={styles.introBox}>
                <div className={styles.introText}>
                  It's a wrap on summer until next year, but you can still find stellar yearlong arts and culture coverage at wbez.org. To hear from us more often, text FUN to 312312 to get a weekly pick of great things to do in and around Chicago. We'll send you our top culture picks ahead of each weekend all season, whether it's a can't-miss local artist, autumnal traditions around the region or our winter survival guide.
                  <br/> <br/>
                  If you're looking for ideas for next summer, or for simply some great reads, click on any link below to read from our collection of Summer 2023 stories. 
                  </div>
                <div className={styles.bylines}>
                  <div className={styles}>
                    <p className={styles}>
                    By Leigh Giangreco, Bianca Cseke and Cassie Walker Burke. With Samantha Callender, Joanna Gaden, Kyle MacMillan and Ysa Quiballo.

                    </p>
                    <p className={styles}>&nbsp;</p>
                    <p className={styles}>
                    Project design and development by Andjela Padejski. With Tenaysia Fox and Fernanda Veles.

                    </p>
                  </div>
                </div>
                </section>
          </section>



            
            
            {/* <div className={styles.filterButtonMobile}> 
              {/* <div className={styles.events2}>200 Events</div> 
              <button
                className={styles.filterParent}
                onClick={openMobileDrawerMenu}
              >
                <div className={styles.filter}>Filter</div>
                <div className={styles.frameDiv}>
                  <img
                    className={styles.vectorIcon4}
                    alt=""
                    src={filterIcon}
                  />
                </div>
              </button>
            </div>
            <div className={styles.guideHeader}>
              <div className={styles.filterMenu}>
                <div className={styles.counterParent}>
                  {/* <div className={styles.counter}>295 Events</div>
                  <div className={styles.categoryButtons} id={"categories"}>
                    <button
                      className={clickedCat["oc"] === false ? styles.filterButton : styles.fbClicked}
                      id="outdoor-concerts"
                      onClick={() => handleCategoryChange("oc")}
                    >
                      <div>
                        outdoor concerts
                      </div>
                    </button>
                    <button
                      className={clickedCat["at"] === false ? styles.filterButton1 : styles.fb1Clicked}
                      id="the-arts"
                      onClick={() => handleCategoryChange("at")}
                    >
                      <div>the ARTS</div>
                    </button>
                    <button
                      className={clickedCat["ww"] === false ? styles.filterButton2 : styles.fb2Clicked}
                      id="workshops-workouts"
                      onClick={() => handleCategoryChange("ww")}
                    >
                      <div>
                        workshops + workouts
                      </div>
                    </button>
                    <button
                      className={clickedCat["nf"] === false ? styles.filterButton3 : styles.fb3Clicked}
                      id="neighborhood-fun"
                      onClick={() => handleCategoryChange("nf")}
                    >
                      <div>
                        neighborhood fun
                      </div>
                    </button>
                  </div>
                  <div className={styles.togglesParent}>
                    <div className={styles.toggles}>
                      <div className={styles.familyFriendlyParent}>
                        <div className={styles.counter}>Family friendly</div>
                        <Switch size="sm" colorScheme="red2" onChange={() => handleFamilyFriendlyOnlyChange()} isChecked={isFamilyFriendlyOnly} />
                      </div>
                      <div className={styles.freeParent}>
                        <div className={styles.free}>Free</div>
                        <Switch size="sm" colorScheme="red2" onChange={() => handleFreeOnlyChange()} isChecked={isFreeOnly} />
                      </div>
                    </div>
                    <div className={styles.resetFiltersParent}>
                      <button className={styles.resetFilters} onClick={() => {navigate("/")}}>
                        Reset filters
                      </button>
                      <div className={styles.sortByDateParent}>
                        <div className={styles.counter}>Sort by date</div>
                        <select onChange={(e) => sortSelection(e.target.value)} className={styles.sichevronRightAlt} value={sortDateAsc.toString()}>
                        <option value={"true"}>Ascending (June to September)</option>
                        <option value={"false"}>Descending (September to June)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <EventCardsContainer
            filterCategory={selectedCategory}
            filterFreeEvents={isFreeOnly}
            filterFamilyFriendly={isFamilyFriendlyOnly}
            sortAsc={sortDateAsc}
          />  */}
          <RelatedStoriesContainer />
          </section>
        </nav>
        
      </main>
      <section id='portals'>
      {isMobileDrawerMenuOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={closeMobileDrawerMenu}
        >
          <MobileDrawerMenu
            isOpen={isMobileDrawerMenuOpen}
            onClose={closeMobileDrawerMenu}
            selectedCategory={selectedCategory}
            onCategoryChange={handleCategoryChange}
            isFreeOnly={isFreeOnly}
            onFreeOnlyChange={handleFreeOnlyChange}
            isFamilyFriendlyOnly={isFamilyFriendlyOnly}
            onFamilyFriendlyOnlyChange={handleFamilyFriendlyOnlyChange}
            sortDateAsc={sortDateAsc}
            onSortSelection={sortSelection}
          />
        </PortalDrawer>
 )}
 </section>
 </>
);
};
export const Head = () => (
 <SEO />
);

export default EventsFinal;
