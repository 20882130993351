import * as React from "react";
import * as styles from "./header.module.css";
import wbez from "/wbez-logo.svg";  //fix file path
import heart from "/heart.svg"; //fix file path

const SiteHeader = () => {
    return (
    <header className={styles.siteheader}>
    <div className={styles.lContainer}>
      <div className={styles.rowMain}>
        <a className={styles.logo} target="_blank" rel="noreferrer" href="https://www.wbez.org">
          <img
            className={styles.logoImg}
            src={wbez}
            alt=""
          />
        </a>

        <div>
          <a
            className={styles.link}
            href="https://donate.wbez.org/?affordance_placement=summerguide"
          >
            Donate <img src={heart} alt="" />
          </a>
        </div> 
        </div> 
      </div>
    </header>
    )
}

export default SiteHeader;